<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
        <el-form label-position="left" >
          <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">积分商城规则配置</div>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="用户端积分别名" class="flex-def">
                <el-input v-model="form.points_alias"></el-input>
              </el-form-item>
              <el-form-item label="积分说明">
                <br>
                <y_editor v-model="form.points_tips"></y_editor>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商城邀请新用户注册赠送积分">
                <el-input-number v-model="form.invite_reward_points"></el-input-number>
              </el-form-item>
              <el-divider content-position="left">小程序端特有</el-divider>
              <el-form-item label="激励视频广告ID">
                <el-input v-model="form.mini_incentive_video_ad_id"></el-input>
              </el-form-item>
              <el-form-item label="刷积分成功奖励积分数值">
                <el-input-number v-model="form.mini_brush_points_reward_num"></el-input-number>
              </el-form-item>
              <el-form-item label="刷积分按钮消失后重现间隔时间">
                <el-input-number v-model="form.mini_brush_points_interval_time"></el-input-number>小时
              </el-form-item>
              <el-form-item label="积分按钮图标">
                <y_upload_img v-model="form.mini_brush_points_icon"></y_upload_img>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item align="right">
            <el-button @click="edit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "conf",
  components:{
    y_upload_img,
    y_editor
  },
  data() {
    return {
      form:{
        points_alias:"",
        points_tips:"",

        invite_reward_points:0,

        mini_incentive_video_ad_id:"",
        mini_brush_points_icon:"",
        mini_brush_points_interval_time:0,
        mini_brush_points_reward_num:0
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.pointsMall.conf().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.shop.plugin.pointsMall.confEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>